import slick from 'slick-carousel';
import $ from 'jquery';
import OfferRepeater from '../../Sections/OfferRepeater/OfferRepeater';

export default {
	init() {

        OfferRepeater.init();

    	// -- carousel -- //

    		var header = document.querySelector('header');

    		var section = document.querySelector('.Online__slider');
    		var slides = section.querySelectorAll('.slider__single');

    		function setHeaderClass( slide ) {

    			if( slide.classList.contains('slider__single--white') ) {

    				header.classList.remove('Header--white');
    				header.classList.add('Header--black');

    			} else {

    				header.classList.remove('Header--black');
    				header.classList.add('Header--white');

    			}

    		}

    		setHeaderClass(slides[0]);

    		$(section).slick({
    			slidesToShow: 1,
    			slidesToScroll: 1,
    			arrows: false,
    			infinite: true,
                rows: false,
                autoplay: true,
                autoplaySpeed: 3000,
                fade: true,
                cssEase: 'linear',
                pauseOnHover: false,
    		});

    		$(section).on('beforeChange', function( event,slick,currentSlide,nextSlide ) {

    			setHeaderClass(slides[nextSlide]);

    		});

    	// -- END -- //	
    	
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
