export default {

	init() {

		var container = document.querySelector('section.OfferRepeater');

		var buttonSwitch = container.querySelector('.movie__image');
		var movieFrameParent = container.querySelector('.movie');
		var movieFrame = movieFrameParent.querySelector('iframe');

		buttonSwitch.addEventListener('click', function() {

			buttonSwitch.classList.add('hide');
			movieFrameParent.classList.remove('hide');

			movieFrame.setAttribute( 'src', movieFrame.getAttribute('src')+'?autoplay=1' );

		});

	},
	finalize() {

	}

}

