export default {

	init() {

		var container = document.querySelector('section.SeparateSection');

		if( container ) {

			// -- video play -- //

				var videoLeft = container.querySelector('.SeparateSection__box--left video');
				var promiseLeft = null;
				var videoRight = container.querySelector('.SeparateSection__box--right video');
				var promiseRight = null;

				if( videoLeft.paused ) {

					promiseLeft = videoLeft.play();

					if( promiseLeft !== undefined ) {

						promiseLeft.then(function() {

							console.log('Left video play');

						}).catch(function(error) {

							videoLeft.play();

						});
					}

				}

				if( videoRight.paused ) {

					promiseRight = videoRight.play();

					if( promiseRight !== undefined ) {

						promiseRight.then(function() {

							console.log('Right video play');

						}).catch(function(error) {

							videoRight.play();

						});
					}

				}

			// -- END -- //

		}


	},
	finalize() {

	}	
}