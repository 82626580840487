import MovieSection from '../../Sections/MovieSection/MovieSection';

export default {

	init() {

		var container = document.querySelector('main.Home');

		if( container ) {

			MovieSection.init();
			
		}

	},
	finalize() {

	}

}