import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default {

	init() {

		// -- heading1 -- //

			var heading = document.querySelector('.SingleRealization__heading1');

			if( heading ) {

				var movieContainer = heading.querySelector('.content__movie');
				var iframe = movieContainer.querySelector('iframe');
				var placeholder = movieContainer.querySelector('.movie__placeholder');

				movieContainer.addEventListener('click', function() {

					placeholder.classList.add('hide');
					iframe.classList.remove('hide');	
					iframe.setAttribute('src', iframe.getAttribute('src')+'?autoplay=1');					

				});

			}

		// -- END -- //

		

		// -- photoswipe -- //
			
			var images = document.querySelectorAll('.ps__image');


			var pswp = document.querySelector('.pswp');
			var items; // photoSwipe images
			var options = Array(); // photoSwipe options
				options.zoomEl = true;
				options.fullscreenEl = false;
				options.shareEl = false;
			var photoSwipe; // photoSwipe object

			function InitPS(index) {

				items = Array();

				options.index = parseInt(index, 10);

				images.forEach( function( image , index2 ) {

					items.push({
						src: image.getAttribute('data-url'),
	                    w: image.getAttribute('data-width'),
	                    h: image.getAttribute('data-height')
					});

				});

				photoSwipe = new PhotoSwipe( pswp, PhotoSwipeUI_Default, items, options);

			}

			images.forEach( function( image, index ) {

				image.addEventListener('click', function() {

					InitPS( index );
					photoSwipe.init();

				});

			});
			
		// -- END -- //

	},
	finalize() {

	}

}