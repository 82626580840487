import SeparateSection from '../../Sections/SeparateSection/SeparateSection';

export default {

	init() {

		var container = document.querySelector('main.Homepage');

		if( container ) {

			SeparateSection.init();

		}

	}

}