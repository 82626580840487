export default {
    init() {
        var t = this;
        window.addEventListener('load', function() {
            t.loadScript();
        });

        if( document.querySelector('.LangSelect') ) {

            var button = document.querySelector('.LangSelect--desktop .LangSelect__button');
            var langBtns = document.querySelectorAll('.LangSelect .LangSelect__wrapper button[data-lang]');

            button.addEventListener('click', () => {
                button.closest('.LangSelect').classList.toggle('active');
            });
            document.addEventListener('click', ( e ) => {
                if( !e.target.closest('.LangSelect') ) {
                    button.closest('.LangSelect').classList.remove('active');
                }
            });
            langBtns.forEach( ( btn, index ) => {
                btn.addEventListener('click', () => {
                    if( document.querySelector('#google_translate_element select') ) {
                        document.querySelector('#google_translate_element select').value = btn.getAttribute('data-lang');
                        document.querySelector('#google_translate_element select').dispatchEvent(new Event('change'));
                        button.querySelector('.button__content').innerHTML = btn.innerHTML;

                        langBtns.forEach( ( item, index2 ) => {
                            if( item.getAttribute('data-lang') == btn.getAttribute('data-lang') ) {
                                item.closest('.list__item').classList.add('hide');
                            } else {
                                item.closest('.list__item').classList.remove('hide');
                            }
                        });

                    }
                });
            });

            let cookieVal = t.checkCookie('googtrans');
            if( cookieVal ) {
                langBtns.forEach( ( item, index2 ) => {
                    if( item.getAttribute('data-lang') == cookieVal.replace('/pl/','') ) {
                        button.querySelector('.button__content').innerHTML = item.innerHTML;
                        item.closest('.list__item').classList.add('hide');
                    } else {
                        item.closest('.list__item').classList.remove('hide');
                    }
                });
            }

        }

    },
    finalize() {
        
    },
    checkCookie( cname ) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    loadScript() {

        if( ( typeof google == 'undefined' || typeof google !== 'object' || typeof google.translate !== 'object' ) && document.querySelector('.LangSelect') ) {
            let select = document.createElement('div');
            select.setAttribute('id', 'google_translate_element');

            let exec = document.createElement('script');
            exec.setAttribute('type', 'text/javascript');
            exec.textContent = "function googleTranslateElementInit() { new google.translate.TranslateElement({pageLanguage: 'pl',includedLanguages:'"+document.querySelector('.LangSelect').getAttribute('data-langs')+"'}, 'google_translate_element'); }";

            let script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

            document.body.appendChild(select);
            document.body.appendChild(exec);
            document.body.appendChild(script);
        }
    }
}