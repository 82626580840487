export default {

	init() {

		var container = document.querySelector('main.ArchiveRealization');

		if( container ) {

			var movieContainer = container.querySelector('section.ArchiveRealization__movie');

			if( movieContainer ) {

				var button = movieContainer.querySelector('.movie__content');
				var iframe = movieContainer.querySelector('iframe');


				button.addEventListener('click', function() {

					iframe.setAttribute('src', iframe.getAttribute('src')+'?autoplay=1');
					button.classList.add('video_play');
					
				});

			}

		}

	},	
	finalize() {

	}

}