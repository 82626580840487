// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import home from '../src/Pages/Home/Home';
import singlerealization from '../src/Pages/SingleRealization/SingleRealization';
import archiverealization from '../src/Pages/ArchiveRealization/ArchiveRealization';
import contact from '../src/Pages/Contact/Contact';
import online from '../src/Pages/Online/Online';

LazyLoad.init();
Subpage.init();

const routes = new Router({
	homepage,
	home,
	singlerealization,
	archiverealization,
	contact,
	online,
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});