import $ from 'jquery';
import Cookie from '../Components/Cookie/Cookie';
import Facebook from '../Components/Facebook/Facebook';
import LangSelect from '../Components/LangSelect/LangSelect';

export default {

	init() {

		// -- Cookie -- // 

			if( !document.querySelector('body').classList.contains('Homepage') ) {

				Cookie.init();
				Facebook.init();
				LangSelect.init();

			}

		// -- END -- //


		// -- MobileMenu -- //

			var body = document.body;

			var hamburger = document.querySelector('header .row__hamburger');
			var mobileMenu = document.querySelector('.MobileMenu');

			if( hamburger && mobileMenu ) {

				var mobileMenuContent = document.querySelector('.MobileMenu__content');

				hamburger.addEventListener('click', function() {

					body.classList.add('disableScroll');
					mobileMenu.classList.add('active');
					hamburger.classList.add('active');

				});

				mobileMenu.addEventListener('click', function() {

					body.classList.remove('disableScroll');
					mobileMenu.classList.remove('active');
					hamburger.classList.remove('active');				

				});	

				mobileMenuContent.addEventListener('click', function(e) {

					e.stopPropagation();

				});

			}

		// -- END -- //



		// -- # element -- //

			var menuItems = document.querySelectorAll('.item__scroll');

			menuItems.forEach( function( item,index ) {

				if( item.getAttribute('href').split('#')[1] ) {

					if( document.querySelector('#'+item.getAttribute('href').split('#')[1]) ) {

						item.addEventListener('click', function(e) {

							e.preventDefault();
							$('html ,body').animate({'scrollTop': document.querySelector('#'+item.getAttribute('href').split('#')[1]).offsetTop }, 'smooth');

							if( mobileMenu.classList.contains('active') ) {

								mobileMenu.classList.remove('active');
								hamburger.classList.remove('active');
								body.classList.remove('disableScroll');

							}

						});

					}

				}

			});		

		// -- END -- //

	},
	finalize() {

	}

}