export default {

	init() {

		var form = document.querySelector('form.ContactForm');

		if( form ) {

			var submitButton = form.querySelector('button.form__submit');
			var formSubmit = false;

			var fields = form.querySelectorAll('.field');

			var requestData = [];
			var request = new XMLHttpRequest();
			var response;

			function callback( message = '', status = 'error' ) {

				form.querySelectorAll('.form__callback').forEach( function( callback,input ) {
					callback.remove();
				});

				if( message ) {

					submitButton.insertAdjacentHTML('beforebegin','<p class="form__callback '+status+'">'+message+'</p>');

				}

			}

			form.addEventListener('submit', function(e) {

				e.preventDefault()

				if( !submitButton.classList.contains('disble') && !formSubmit ) {

					submitButton.classList.add('disable');

					fields.forEach( function( input,index ) {

						if( input.classList.contains('required') ) {

							if( input.type == 'checkbox' ) {

								if( !input.checked ) {

									input.closest('label').classList.add('empty');

								} else {

									input.closest('label').classList.remove('empty');

								}

							} else {

								if( !input.value ) {

									input.closest('.form__field').classList.add('empty');

								} else {

									input.closest('.form__field').classList.remove('empty');

								}

							}

						}

						if( input.type == "email" ) {

							if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

								input.closest('.form__field').classList.remove('error');

							} else {

								input.closest('.form__field').classList.add('error');

							}

						}

					});

					if( form.querySelectorAll('.empty').length > 0 ) {

						callback( form.getAttribute('data-field-empty') );
						submitButton.classList.remove('disable');

					} else if( form.querySelectorAll('.error').length > 0 ) {

						callback( form.getAttribute('data-email-error') );
						submitButton.classList.remove('disable');

					} else {

						callback();

						// -- Sending data -- //

							requestData = [];
							requestData.push( 'action=sendMail' );
							requestData.push( 'security='+form.querySelector('input#security').value );

							fields.forEach( function( input,index ) {
 
								if( input.type != 'checkbox' && input.value ) {

									requestData.push( input.getAttribute('name')+'='+input.value );

								}

							});

							requestData = requestData.join('&');

						// -- END -- //


						// -- Request -- //

							request = new XMLHttpRequest();
							request.onreadystatechange = function() {

								if( request.responseText && request.readyState == 4 && request.status == 200 ) {

									response = JSON.parse(request.responseText);

									if( response.status == 'success' ) {

										callback( form.getAttribute('data-form-success') , 'success');
										formSubmit = true;

									} else if( response.status == 'error' ) {

										callback( form.getAttribute('data-form-error') );
										submitButton.classList.remove('disable');

									}

								}

							}

							// Set up our request
							request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

							// Add the required HTTP header for form data POST requests
							request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

							// Finally, send our data.
							request.send(requestData);

						// -- END -- //

					}

				}

			});


		}

	},
	finalize() {

	}

}	